/* You can add global styles to this file, and also import other style files */

body {
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  z-index: -5;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

//import font
* {
  font-family: "alfarn-2", sans-serif;
  font-weight: 700;
  font-style: normal;
}

router-outlet + * {
  z-index: 1;
}

.mobile-view {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-height: 500px;
}

@media only screen and (max-width: 480px) {
  router-outlet + * {
    display: none !important;
  }
  .mobile-view {
    display: flex !important;
    width: 350px;
    * {
      text-align: center;
    }
  }
  app-sponsors {
    padding: 0px !important;
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  button {
    i {
    }
  }
  .icon-button {
    border-radius: 50%;
    aspect-ratio: 1;
    width: 30px;
  }
  .icon-button:hover {
    background-color: #5f399e;
    color: white;
  }
  .icon-button:active {
    transform: scale(0.9);
    box-shadow: 1px 1px 1px 1px purple;
  }
}

$major: #007a49;
$majorHover: #00663d;
$basicCell: #ffffff;
$basicCellHover: #f2f2f2;
$moneyCell: #eaf7cb;
$moneyCellHover: #5b7915;
$secondary: #f5f5f5;
$secondaryLight: #ffffff;
$secondaryDark: #e3c7e2;
$cardColorBack: #5f399e;
$cardColorBackDarker: #591db9;
$cardColorFront: #ffffff;
$cardFont1: #66459c;
$cardFont2: #ac4399;
$cardFont3: #332f30;
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1056;
  animation: modalIn 0.5s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

@keyframes modalIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.action-modal {
  background-color: $secondaryDark;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 2px black;
  height: 500px;
}

.modal-content {
  height: 100%;
  padding: 20px;
}

.cash-modal {
  background-color: $moneyCell;
}

.modal-dialog {
  width: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .modal-title {
  }
  .modal-body {
  }
  .modal-footer {
  }
}

$cardwidth: 2.5;
$cardheight: 3.5;
$cardMeasurements: 100px;
.cards {
  position: relative;
  max-width: 380px;
  width: 0;
  height: calc(#{$cardMeasurements} * #{$cardheight}+50px);
  $n: 86;
  @for $i from $n through 1 {
    .card:nth-child(#{$i}) {
      position: absolute;
      left: calc(($cardMeasurements * $cardwidth) / -2 - 8px + 0.2px * $i);
      top: -0.2px * ($i - ($n / 2));
      z-index: $n - $i;
      transform: rotateX(0) rotateY(0) rotateZ(0.15deg * ($i - 1));
    }
  }
}

.card {
  width: calc(#{$cardMeasurements} * #{$cardwidth});
  height: calc(#{$cardMeasurements} * #{$cardheight});
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: transform 1s linear, box-shadow 1s ease-in;
}

.card-back {
  //background-color: $cardColorBack;
  //border: 5px outset $cardColorBackDarker;\
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.card-front {
  background-color: $cardColorFront;
  //border: 5px outset $cardFont2;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

button {
  cursor: pointer;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
}

.flex-row-multiline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.dot {
  height: 9px;
  width: 9px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  pointer-events: none;
  top: calc(1.25rem - 2px);
}

.card-category {
  color: #66459c;
  flex-shrink: 0;
  height: 1.5rem;
  padding: 7px 30px;
}

.card-category.selected {
  background-color: #66459c;
  color: white;
}

/* Vicotry MOdal */

.victory-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(63, 62, 62);
  opacity: 60%;
  z-index: 1000;
  animation: victory-backdrop-fadein 5s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(63, 62, 62);
  opacity: 60%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes victory-backdrop-fadein {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 60%;
  }
}

.victory-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1056;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-modal {
  background-color: $secondaryDark;
}

.victory-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .modal-content {
    width: 100%;
    height: 100%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgb(44, 43, 43);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.side-logo {
  width: 150px;
  position: absolute;
  z-index: 10;
  opacity: 0.7;
  left: 40px;
  bottom: 40px;
}

@media screen and (max-width: 1600px) {
  .side-logo {
    width: 100px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    opacity: 0.8;
  }
}

.home-button {
  position: absolute;

  top: 10px;
  right: 10px;
  padding: 4px 10px;
  font-size: large;
  border: #591db9 3px solid;
  font-size: 2rem;

  border-radius: 2rem;
  transition: transform 0.1s ease-in-out;
  transform-origin: bottom center;
}

.home-button:hover {
  background-color: #591db9;
  color: white;
}

.home-button:active {
  transform: scale(0.95);
  box-shadow: 0px 1px 4px 4px purple;
}
